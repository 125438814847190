/* Header */
/*-----------------------------------------------------------------------------*/
header {
  border-top: 5px solid #0463d7;
  background-color: #fff;
  padding: 5px 0;
}
header .logo {
  padding-top: 18px;
}
header .mtl-bar {
  text-align: right;
  background: #0067c6;
  clear: both;
  margin-top: 85px;
}
header nav {
  margin-top: 10px;
  float: right;
}
header nav li {
  float: left;
}
header nav li a {
  color: #999;
  border-left: 1px solid #ccc;
  font-weight: normal;
  font-size: 12px;
  padding: 0 10px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
header nav li a:hover {
  text-decoration: none;
}
header nav li .last {
  padding-right: 0;
}
header nav li .first {
  border-left: 0;
}
header .sub-nav {
  float: right;
  margin-top: 10px;
}
header .sub-nav li {
  float: left;
}
header .sub-nav li a {
  font-weight: bold;
  padding: 0 10px;
  border-right: 1px solid;
  font-size: 12px;
  -webkit-transition: all 200ms ease-out;
  -moz-transition: all 200ms ease-out;
  -ms-transition: all 200ms ease-out;
  -o-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
}
header .sub-nav form {
  display: inline;
  line-height: 10px;
}
header .sub-nav form input[type=submit] {
  background: none;
  color: #337ab7;
  border: none;
  display: inline;
  font-weight: bold;
  font-size: 12px;
  border-right: 1px solid;
}
header .sub-nav .last {
  padding-right: 0;
  border-right: 0;
}
main > .container,
main #search > .container,
main #results > .container {
  background-color: #fff;
  -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}
main > .container > .row,
main #search > .container > .row,
main #results > .container > .row {
  margin-top: 20px;
  margin-bottom: 20px;
}
main #search {
  margin-bottom: 40px;
}
main #search form .left {
  padding-right: 0;
}
main #search form .right {
  padding-left: 0;
}
main #search form .right fieldset {
  border-left-width: 0;
}
main #search form .restricted {
  color: #a94442;
}
main #search form label {
  font-size: 12px;
}
main #certificate .certificate-info > div {
  overflow: hidden;
  margin: 5px 0;
  padding-bottom: 5px;
  border-bottom: 1px solid #dddddd;
}
main #certificate .info-label {
  font-weight: bold;
  margin-top: 5px;
}
main #certificate .info {
  text-align: left;
  background-color: #f3f3f3;
  padding: 5px;
}
main #certificate div.alert.alert-warning {
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 12px;
}
main .actions {
  z-index: 9999;
  width: 100%;
  height: 1px;
  opacity: 0;
}
main .actions .wrap {
  border: 1px solid #ccc;
  background: #fff;
  padding: 5px;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px;
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
}
main .actions.is_stuck {
  opacity: 1 !important;
  height: auto;
}
footer {
  padding-top: 18px;
}
footer .container {
  background-image: linear-gradient(#e7e7e7 0%, #ffffff 100%);
  padding: 10px 16px 30px;
}
/*# sourceMappingURL=_/css/main.css.map */